export const getStaticProps = async () => {
  return {
    props: {},
  };
};

function Home() {
  return (
    <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-bold tracking-tight text-indigo-600 sm:text-5xl">
            <img
              src="https://assets.riskfactor.com/uploads/2022/05/RF-PRo-logo.png"
              alt="Riskfactor logo"
              width="200"
            />
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Thank you ULI Members!
              </h1>
              <p className="mt-1 text-base text-gray-500">
                Thank you for taking the time to test Risk Factor Pro. <br />
                We will be in touch shortly with next steps on our launch day,{' '}
                <strong>Monday, October 24th</strong>.
              </p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <a
                href="https://riskfactor.com"
                className="inline-flex items-center rounded-md border border-transparent bg-riskfactor-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Go to RiskFactor.com
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Home;
